.vfont{
    text-align: left;
    background-color: #f5f5f5;
    border: 1px groove ;
    font-size: 20px;
    border-color: #9f9f9f;

}
.vision{
    background: rgb(249, 245, 239);
    padding: 1px 100px 1px 100px;
}
.visionh{
    text-align: left;
    font-size: 30px;
    font-style: bold;
}