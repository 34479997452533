.welcomepic1{
    display: flex;
    flex-direction: column;
}
.welcomepic12{
    display: flex;
    flex-direction: column;
    height: 520px;
}

.swamijifooter{
    background-color: orange;
    color: black;
    text-align: center;
    font-size: 20px;
    width: 480px;
    height: 200%;

}
.welcomepic3{
    align-self: center;
    margin: 60px;
}
.welcomepic{
    display: flex;
    justify-content: center;
}
.pic2{
    position: relative;
    height: 505px;
    top: 0;
    left: 0px;
}
.wlcfont{
    color: green;
}
.pic{
    position: relative;
    width: 100%;
    top: 0;
    left: 0px;
}