.commitment{
    padding: 20px;
    background: #EDF7FF
}
.commitfont{
    justify-content: center;
    padding: 35px;
}
.commit{
    display: flex;
    padding: 10px;
}
.clgpic1{
    height: 300px;
}
.comment{
color: green;
font-size: 50px;
}
.map1{
    color: red;
}