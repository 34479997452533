.phlog{
    background-color: white;
    margin-left: 80px;
    padding: 0px;
    width: 40px;
    height: 80%;
    border-radius: 20px;
  }
  .header{
    background-color: black;
    color: white;
    font-size: 15px;
    display: flex;
  }
  .headerfont{
    margin-right: 40px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .headerfont2{
    margin-right: 40px;
    margin-left: 500px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  } 
 