.navigation{
    background-color:green;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
}
.navfont{
  margin-left:30px;
  font-size: 20px;
}
.stjitlogo{
  margin-left: 80px;
  height: 110%;
}
.navfont:hover{
  color: orange;
}