.str1{
    display: flex;
    padding: 10px 100px 10px 100px;
    justify-content: center;

}
.str2{
    font-size: 35px;
    width: 280px; 
     margin: 20px; 
    justify-content: center;
    background-color: orange;
     border-radius: 50%;
    color: white;
}
.strength1{
    display: flex;
}
.str2:hover{
    background-color: green;
    transition:.5s;
}
.strength{
    font-size: 50px;
    color: green;
}